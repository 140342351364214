import React from "react";
import { useGetUserProgressQuery } from "services/graphql";
import Flex from "app/components/Flex";
import Text from "app/components/Text";
import styled from "styled-components";
import abbreviateCount from "helpers/abbreviateCount";
import { H1, H3 } from "app/components/Typography";
import { useGetUserProfile } from "hooks/User";
import { useMediaQuery } from "react-responsive";

const StrokeText = styled(Text)`
  color: transparent;
  text-align: left;
  line-break: 1;
  font-weight: 800;
  -webkit-text-stroke: ${({ theme, textStroke }) => theme.colors[textStroke]}
    1px;
`;

export function UserSummary() {
  const { data: userData } = useGetUserProgressQuery();
  const { user } = userData || {};

  const { profileData } = useGetUserProfile();
  const { username } = profileData;
  const isSmallScreen = useMediaQuery({
    maxWidth: 768,
  });
  const Header = isSmallScreen ? H3 : H1;

  return (
    <Flex p={{ _: 3, lg: 4 }} bg="black" flexDirection="column">
      <Header color="white" mb="16px">
        {username ? `Welcome, @${username}!` : "Welcome!"}
      </Header>
      <Flex>
        <Flex flexDirection="column" mr={3}>
          <StrokeText fontSize="45px" textStroke="white">
            {abbreviateCount(
              Math.round(user?.progress?.total_time_spent / 60) || 0
            )}
          </StrokeText>
          <Text color="white" fontSize="12px">
            Total Minutes Spent
          </Text>
        </Flex>
        <Flex flexDirection="column">
          <StrokeText fontSize="45px" textStroke="white">
            {user?.progress?.classes_completed || 0}
          </StrokeText>
          <Text color="white" fontSize="12px">
            Classes Completed
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
